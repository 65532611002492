<template>
  <b-row
    :id="paymentStatus.id"
    :class="hasInvoiceDisputeReason || requiredCaptureStatus ? 'pointer' : ''"
    no-gutters
  >
    <div
      :class="getPaymentBadge(paymentStatus.status)"
    >
      <div class="badge-text-sm">
        {{ $t('payment_status.' + paymentStatus.status) }}
        <span
          v-if="hasInvoiceDisputeReason || requiredCaptureStatus"
          :id="'target' + paymentStatus.id"
          class="fa fa-info-circle"
        />
      </div>

      <b-popover
        v-if="hasInvoiceDisputeReason || requiredCaptureStatus"
        :target="paymentStatus.id"
        :container="paymentStatus.id"
        :custom-class="requiredCaptureStatus ? 'captured-parent' : 'disputed-parent'"
        triggers="hover focus"
      >
        <template #title>
          <div>
            <div>
              <i class="pr-1 fa fa-bell"/>
              {{ $t('views.payment.content.full-infos.payment-label') }}
              {{ $t('payment_status.' + paymentStatus.status).toLocaleLowerCase() }}
            </div>
          </div>
        </template>
        <div>
          <div class="content">
            <div class="title">
              {{ description }}
            </div>
          </div>
        </div>
      </b-popover>
    </div>
  </b-row>
</template>
<script>
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  props: {
    paymentStatus: {
      type: String,
      default: this
    }
  },
  computed: {
    description() {
      return this.requiredCaptureStatus ? this.$t('views.payment.content.full-infos.status.requires_capture') : this.$t('views.payment.content.full-infos.status.' + this.paymentStatus.metadata.invoiceDisputeReason);
    },
    requiredCaptureStatus() {
      return this.paymentStatus.status === 'requires_capture';
    },
    hasInvoiceDisputeReason() {
      if (null !== this.paymentStatus.metadata) {
        return isNotUndefinedAndNotNull(this.paymentStatus.metadata.invoiceDisputeReason);
      }
      return false;
    },
  },
  methods: {
    getPaymentBadge(status) {
      switch (status) {
        case 'processing':
          return 'badge-score-pending-confirmation';
        case 'requires_capture':
          return 'badge-require-capture';
        case 'succeeded':
          return 'badge-available-success-confirmation';
        case 'failed':
          return 'badge-available-failed-confirmation';
        case 'refunded':
          return 'badge-available-refunded-confirmation';
        case 'disputed':
          return 'badge-available-disputed-confirmation';
        case 'canceled':
          return 'badge-available-error-confirmation';
      }
    },
  }
}
</script>
<style scoped lang="scss">
/deep/ .captured-parent .popover-header {
  background-color: #757978;
  color: #fff;
  padding: 7px;
  font-size: small;
}

/deep/ .disputed-parent .popover-header {
  background-color: #E95060;
  color: #fff;
  padding: 7px;
  font-size: small;
}


.badge-score-pending-confirmation {
  background: #F4F0E2;
  border-radius: 6px;
  opacity: 1;
  color: #9D774A;
  text-align: center;
  letter-spacing: 0px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 11px;
  padding-right: 11px;
}

.badge-available-success-confirmation {
  background: #E2F4EC;
  border-radius: 6px;
  opacity: 1;
  color: #43946e;
  letter-spacing: 0;
  padding-top: 10px;
  text-align: center;
  padding-bottom: 10px;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
}

.badge-available-error-confirmation {
  background: #F4E2E2;
  border-radius: 6px;
  opacity: 1;
  color: #E95060;
  text-align: center;
  letter-spacing: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
}

.badge-available-refunded-confirmation {
  background: #ffe5d8;
  border-radius: 6px;
  opacity: 1;
  color: #f58551;
  text-align: center;
  letter-spacing: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
}

.badge-require-capture {
  background: #e3e3e3;
  border-radius: 6px;
  opacity: 1;
  color: #757978;
  text-align: center;
  letter-spacing: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
}

.badge-available-disputed-confirmation {
  background: #F4E2E2;
  border-radius: 6px;
  opacity: 1;
  color: #E95060;
  text-align: center;
  letter-spacing: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
}

.badge-available-failed-confirmation {
  background: #e5e4e4;
  border-radius: 6px;
  opacity: 1;
  color: #a09797;
  text-align: center;
  letter-spacing: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.content .title {
  color: #52575D;
  text-align: left;
  font: normal normal normal 13px Avenir;
  letter-spacing: 0;
  white-space: initial;
  opacity: 1;
}

</style>
